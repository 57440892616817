@font-face {
  font-family: bree;
  src: url("breeserif-regular.dd41a54c.ttf");
}

:root {
  --base-txt-color: #222;
  --heading-color: --brand-dark;
  --base-font-family: "Work Sans", sans-serif;
  --body-bg: #fff;
  --brand-light: #00d1c3;
  --brand-dark: #07a898;
  --link-color: --brand-dark;
  --header-active: #057265;
  --header-inactive: #9b9b9b;
  --info-one: #f9c82d;
  --info-two: #61ce63;
  --info-three: #6390d6;
  --action-color: #ef7043;
  --brand-lighter: #5cf2e4;
  --bginfo-color: #fcf3e2;
  --disabled-color: #c6c6c6;
}

.contact-header {
  margin-top: 2em;
}

#peta-contact-logo {
  width: 300px;
}

.page-form {
  max-width: 500px;
  margin: auto;
}

input[type="text"], input[type="email"], select, textarea {
  box-sizing: border-box;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 16px;
  padding: 12px;
  font-size: 1.1em;
  transition: border .3s ease-in-out;
}

input:active, select:active, textarea:active, input:focus, select:focus, textarea:focus {
  border: solid 2px var(--brand-light);
  border-color: var(--brand-light);
  outline: none;
}

.container {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
}

input, textarea, label {
  font-family: Verdana, Tahoma, sans-serif;
}

.required {
  color: red;
  font-size: .7em;
  position: absolute;
  top: 7px;
  right: 5px;
}

.input-holder {
  width: 100%;
  position: relative;
}

.input-warning {
  border: 1px solid red !important;
}

@media (width <= 768px) {
  .page-form {
    max-width: 80%;
  }

  #peta-contact-logo {
    width: 40%;
  }
}

@media (width <= 450px) {
  .page-form {
    max-width: 90%;
  }

  #peta-contact-logo {
    width: 60%;
  }
}

* {
  box-sizing: border-box;
}

#root {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 100%;
  overflow: hidden;
}

.content-panel {
  width: 100%;
  height: 100%;
  padding: 0 0 20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.action-btn {
  background-color: var(--action-color);
  color: #fff;
  cursor: pointer;
  border: none;
  width: 200px;
  margin: 10px;
  padding: .4em;
  font-size: 1.3em;
  transition: all .3s;
  display: inline-block;
  transform: skewX(-15deg);
}

.action-btn:hover {
  box-shadow: 0 0 4px #00000080;
}

.totop-btn {
  background-color: var(--brand-dark);
  color: #fff;
  cursor: pointer;
  border: none;
  width: 200px;
  margin: 9px;
  padding: .4em;
  font-size: 1.1em;
  transition: all .3s;
  display: inline-block;
  transform: skewX(15deg);
}

.totop-btn:hover {
  box-shadow: 0 0 4px #00000080;
}

.full-page {
  height: 100vh;
}

.v-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.h-center {
  text-align: center;
}

.divide-line {
  border-bottom: 1px solid var(--brand-dark);
  width: 70%;
  margin: 30px auto;
}

.clear-fix:after {
  content: "";
  clear: both;
  display: table;
}

h1, h2, h3, h4, h5, h6 {
  font-family: bree, Tahoma, sans-serif;
  font-weight: 600;
}

.hidden {
  display: none;
}

@media (width <= 768px) {
  .divide-line {
    width: 90%;
  }
}

@media (width <= 450px) {
  .action-btn {
    width: 180px;
    padding: .3em;
    font-size: 1.1em;
  }

  .totop-btn {
    width: 160px;
    padding: .3em;
    font-size: 1em;
  }
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: var(--bginfo-color);
  border-radius: 500px;
}

::-webkit-scrollbar-thumb {
  background: var(--brand-light);
  border-radius: 500px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--brand-dark);
}

#section-1 {
  height: 95vh;
}

#logo-holder {
  margin: 20vh 0 30vh;
  position: relative;
}

#peta-cover {
  width: 270px;
}

#peta-line {
  animation: 4s linear infinite lineslide;
  position: absolute;
  top: 97px;
  left: 60px;
}

@keyframes lineslide {
  10% {
    top: 97px;
    left: 60px;
    transform: rotate(0);
  }

  20% {
    top: 110px;
    left: 220px;
    transform: rotate(40deg);
  }

  25% {
    top: 130px;
    left: 250px;
    transform: rotate(90deg);
  }

  30% {
    top: 160px;
    left: 230px;
    transform: rotate(140deg);
  }

  40% {
    top: 160px;
    left: 60px;
    transform: rotate(180deg);
  }

  60% {
    top: 160px;
    left: 60px;
    transform: rotate(180deg);
  }

  70% {
    top: 160px;
    left: 230px;
    transform: rotate(140deg);
  }

  75% {
    top: 130px;
    left: 250px;
    transform: rotate(90deg);
  }

  80% {
    top: 110px;
    left: 220px;
    transform: rotate(40deg);
  }

  90% {
    top: 97px;
    left: 60px;
    transform: rotate(0);
  }
}

#section-2, #section-3 {
  padding: 0 20vw;
  position: relative;
}

#pin-up {
  z-index: 50;
  width: 80px;
  position: absolute;
  top: 50px;
}

#pin-notes {
  background-color: #f7f5dd;
  border-radius: 10px;
  margin: 120px 10vh 20px;
  padding: 3em 2em 2em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.3em;
  transform: skewY(-4deg);
  box-shadow: 0 0 6px #0006;
}

#note-plane {
  float: right;
}

#logo-second {
  width: 30vw;
  margin: 8vh 0;
}

.end-section {
  padding-top: 15%;
}

.to-top {
  text-align: right;
  padding-top: 15%;
}

@media (width <= 1100px) {
  #logo-second {
    width: 40vw;
  }

  .to-top {
    text-align: right;
    padding-top: 20%;
  }

  #section-2 {
    padding: 0 10vw;
  }
}

@media (width <= 768px) {
  #logo-second {
    width: 50vw;
    margin: 4vh 0;
  }

  .to-top {
    text-align: right;
    padding-top: 30%;
  }

  #section-2 {
    padding: 0 4vw;
  }

  #pin-notes {
    margin: 120px 3vw 20px;
    padding: 3em 1.5em 1.5em;
    font-size: 1.1em;
  }
}

@media (width <= 450px) {
  #logo-second {
    width: 60vw;
  }

  #pin-notes {
    padding: 3em 1.3em 1.3em;
    font-size: 1.1em;
  }
}

.spinner {
  border-top: 4px solid var(--brand-light);
  border-right: 4px solid #0000;
  border-radius: 50%;
  margin: auto;
  animation: .8s linear infinite rotation;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.sp-lg {
  width: 115px;
  height: 115px;
}

.sp-sml {
  width: 50px;
  height: 50px;
}

.work-box {
  width: 50vw;
}

.project-box {
  margin-top: 50px;
  margin-bottom: 50px;
}

.work-notes {
  text-align: justify;
  padding: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
}

.work-logo {
  float: right;
  width: 65px;
  margin: 40px 10px 20px 30px;
}

.work-emblem {
  width: 150px;
  margin: auto;
}

@media (width <= 768px) {
  .work-box {
    width: 75vw;
  }

  .work-notes {
    font-size: 1.1em;
  }
}

@media (width <= 450px) {
  .work-notes {
    padding: 1em;
    font-size: 1em;
  }

  .work-box {
    width: 90vw;
  }
}

@media (width >= 370px) {
  .work-emblem {
    float: left;
    margin: 5px 30px 20px 10px;
  }
}

/*# sourceMappingURL=index.d4f7eca5.css.map */
