
* { box-sizing: border-box; }
#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;  
  overflow: hidden;
}

body {
  padding: 0;  
  margin: 0;
  height: 100vh;
  font-size: 100%;
  overflow: hidden; //this removes an unwanted full length scroll bar
}

.content-panel { 
  height: 100%; 
  width: 100%; 
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 0 0 20px 0;
}

.clear-fix::after { content: ''; clear: both; display: table; }

.action-btn {
  width: 200px;
  display: inline-block;
  background-color: var(--action-color);
  color: white;
  transform: skewX(-15deg); 
  border: none;
  font-size: 1.3em;
  padding: .4em;
  transition: all .3s;
  margin: 10px;
  cursor: pointer;
}
.action-btn:hover { box-shadow: 0px 0px 4px rgba(0,0,0,0.5); }
.totop-btn{
  width: 200px;
  display: inline-block;
  background-color: var(--brand-dark);
  color: white;
  transform: skewX(15deg); 
  border: none;
  font-size: 1.1em;
  padding: .4em;
  transition: all .3s;
  margin: 9px;
  cursor: pointer;
}
.totop-btn:hover { box-shadow: 0px 0px 4px rgba(0,0,0,0.5); }
.full-page { height: 100vh; }
.v-center {  display: flex; align-items: center; justify-content: center; }
.h-center { text-align: center; }
.divide-line {
  width: 70%;
  margin: 30px auto;
  border-bottom: 1px solid var(--brand-dark);
}
.clear-fix::after { content: ''; clear: both; display: table; }

h1,h2,h3,h4,h5,h6 { font-weight: 600; font-family: 'bree', Tahoma, sans-serif; }
.hidden { display: none;  }

@media all and (max-width: 768px){
  .divide-line { width: 90%; }
}

@media all and (max-width: 450px){
  .action-btn { width: 180px; font-size: 1.1em; padding: .3em; }
  .totop-btn{ width: 160px; font-size: 1em; padding: .3em; }
}
