  .spinner{
    border-top: 4px solid var(--brand-light);
    border-right: 4px solid transparent;
    border-radius: 50%;
    animation: rotation .8s linear infinite;
    margin: auto;
  }
  @keyframes rotation{
    from{ transform: rotate(0deg); }
    to{ transform: rotate(360deg); }
  }
  .sp-lg { height: 115px; width: 115px; }
  .sp-sml { height: 50px; width: 50px; }
