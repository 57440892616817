
.work-box{ width: 50vw; }

.project-box{ margin-top: 50px; margin-bottom: 50px; }

.work-notes{
  padding: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2em;
  text-align: justify;
}

.work-logo{
  width: 65px;
  margin: 40px 10px 20px 30px;
  float: right;
}

.work-emblem{ width: 150px; margin: auto; }

@media all and (max-width: 768px){
  .work-box{ width: 75vw; }
  .work-notes{ font-size: 1.1em; }
 }

@media all and (max-width: 450px){
  .work-notes{ padding: 1em; font-size: 1em; }
  .work-box{ width: 90vw; }
 }

 @media all and (min-width: 370px){
  .work-emblem{ margin: 5px 30px 20px 10px; float: left; }
 }