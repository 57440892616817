#section-1 { height: 95vh; }
#logo-holder { position: relative; margin: 20vh 0 30vh 0; }
#peta-cover { width: 270px; }
#peta-line {
  position: absolute;
  animation: lineslide 4s linear infinite;
  top: 97px;
  left: 60px;
}
@keyframes lineslide {
  10%{ transform: rotate(0deg); top: 97px; left: 60px; } 
  20%{ transform: rotate(40deg); top: 110px; left: 220px; }
  25%{ transform: rotate(90deg); top: 130px; left: 250px; }
  30%{ transform: rotate(140deg); top: 160px; left: 230px; }
  40%{ transform: rotate(180deg); top: 160px; left: 60px; }
  60%{ transform: rotate(180deg); top: 160px; left: 60px; }
  70%{ transform: rotate(140deg); top: 160px; left: 230px; }
  75%{ transform: rotate(90deg); top: 130px; left: 250px; }
  80%{ transform: rotate(40deg); top: 110px; left: 220px; }
  90%{ transform: rotate(0deg); top: 97px; left: 60px; }
}

#section-2, #section-3 { 
  position: relative; 
  padding: 0 20vw;
}

#pin-up {
  width: 80px;
  z-index: 50;
  position: absolute;
  top: 50px;
}

#pin-notes{
  background-color: rgb(247, 245, 221);
  padding: 3em 2em 2em 2em;
  font-size: 1.3em;
  border-radius: 10px;
  margin: 120px 10vh 20px 10vh;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.4);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  transform: skewY(-4deg);
}

#note-plane{
  float: right;
}

// .sub-sections { 
//   height: 95vh; 
//   min-height: 500px;
//   position: relative; 
//   margin-bottom: 1em;
//   width: 100vw;
//   z-index: 10;
// }

// .left-arrow, .right-arrow { transition: all .5s ease-in-out; }
// .left-arrow:hover { transform: rotate(100deg); }
// .right-arrow:hover { transform: rotate(-100deg);  }

// .main-section-left { float: left; width: 65%; height: 100%; }
// #mt-display { width: 80%; padding-left: 5%; }
// .main-section-right {
//   float: left;
//   width: 27%;
//   height: 100%;  
// }
#logo-second { margin: 8vh 0; width: 30vw; }

// #infograph { height: 100%; max-width: 95vw; margin-top: 5%;}
// .full-section { height: 90vh; min-height: 500px; margin: 5vh; }
.end-section { padding-top: 15%; }
.to-top { text-align: right; padding-top: 15%; }

@media all and (max-width: 1100px){
  // .main-section-right { padding: 5vh 0; }
  #logo-second { width: 40vw; }
  .to-top { text-align: right; padding-top: 20%; }
  #section-2{ 
    padding: 0 10vw;
  }
}

@media all and (max-width: 768px){
  // .sub-sections { height: auto; padding: 30px 0 0 0; }
  // .main-section-left {
  //   float: none;
  //   width: 100%;
  //   height: auto;
  // }
  // #mt-display { width: 90%; padding-left: 0; }

  // .left-arrow, .right-arrow { display: none; }
  // .main-section-right {
  //   float: none;
  //   width: 100%;
  //   height: auto;
  //   padding: 0;
  // }
  #logo-second { width: 50vw; margin: 4vh 0; }
  .to-top { text-align: right; padding-top: 30%; }
  // .full-section { height: 90vw; min-height: 100px; margin: 5vh 0; }
  #section-2{ 
    padding: 0 4vw;
  }
  #pin-notes{
    padding: 3em 1.5em 1.5em 1.5em;
    font-size: 1.1em;
    margin: 120px 3vw 20px 3vw;
  }
}

@media all and (max-width: 450px){
  // #enyes-display { width: 100%; }
  #logo-second { width: 60vw; }
  #pin-notes{
    padding: 3em 1.3em 1.3em 1.3em;
    font-size: 1.1em;
  }
}
