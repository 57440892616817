.contact-header { margin-top: 2em; }
#peta-contact-logo { width: 300px; }
.page-form { max-width: 500px; margin: auto; }

input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  font-size: 1.1em;
}
input:active, select:active, textarea:active, input:focus, select:focus, textarea:focus {
  border: solid 2px var(--brand-light); border-color: var(--brand-light);  outline: none;
} 
.container { border-radius: 5px; background-color: #f2f2f2; padding: 20px; }
input, textarea, label { font-family: Verdana, Tahoma, sans-serif; }
.required {
  color: red;
  font-size: 0.7em;
  position: absolute;
  right: 5px;
  top:7px;
}
.input-holder { position: relative; width: 100%; }
.input-warning{ border: solid 1px red !important; }

@media all and (max-width: 768px){
  .page-form { max-width: 80%; }
  #peta-contact-logo { width: 40%; }
 }

@media all and (max-width: 450px){
  .page-form { max-width: 90%; }
  #peta-contact-logo { width: 60%; }
 }
